.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background-color: #FFFFFF;
  padding: calc(20px * var(--scale));
  border-radius: calc(8px * var(--scale));
  width: calc(500px * var(--scale));
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.close-button-wrapper {
  position: absolute;
  top: calc(10px * var(--scale));
  right: calc(10px * var(--scale));
}

.close-button {
  background: none;
  border: none;
  font-size: calc(24px * var(--scale));
  color: #141414;
  cursor: pointer;
}

.title {
  font-size: calc(25px * var(--scale));
  line-height: calc(35px * var(--scale));
  font-weight: 600;
  color: #141414;
  text-align: center;
  margin-bottom: calc(10px * var(--scale));
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: calc(20px * var(--scale));
}

.field-group {
  width: 100%;
  margin-bottom: calc(30px * var(--scale));
}

.label {
  display: block;
  font-size: calc(18px * var(--scale));
  font-weight: 600;
  color: #374151;
  margin-bottom: calc(10px * var(--scale));
}

.required {
  color: #F1414A;
}

.error-text {
  font-size: calc(14px * var(--scale));
  color: #F1414A;
  margin-left: calc(10px * var(--scale));
}

.input, .textarea {
  width: 100%;
  padding: calc(12px * var(--scale));
  border-radius: calc(8px * var(--scale));
  border: 1px solid #E5E7EB;
  background-color: #F9FAFB;
  font-size: calc(16px * var(--scale));
  color: #374151;
}

.textarea {
  height: calc(200px * var(--scale));
}

.submit-button {
  width: calc(320px * var(--scale));
  height: calc(50px * var(--scale));
  background-color: #2563EB;
  color: #FFFFFF;
  border-radius: calc(10px * var(--scale));
  font-size: calc(16px * var(--scale));
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
}

.submit-button:disabled {
  background-color: #A7C1E1;
  cursor: not-allowed;
}

.message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: calc(20px * var(--scale));
  box-sizing: border-box;
}

@media screen and (max-width: 431px) {
  .modal-content {
    width: calc(390px * var(--mobile-scale));
    max-height: calc(514px * var(--mobile-scale));
    padding: calc(30px * var(--mobile-scale));
  }

  .title {
    font-size: calc(18px * var(--mobile-scale));
    line-height: calc(25px * var(--mobile-scale));
  }

  .label,
  .input,
  .textarea,
  .submit-button,
  .error-text {
    font-size: calc(16px * var(--mobile-scale));
    line-height: calc(25px * var(--mobile-scale));
  }
  .input{
    height: calc(50px * var(--mobile-scale));
    padding: calc(12px * var(--mobile-scale));
  }

  .textarea {
    height: calc(200px * var(--mobile-scale));
    padding: calc(12px * var(--mobile-scale));
  }

  .close-button {
    font-size: calc(24px * var(--mobile-scale));
  }
  .close-button-wrapper {
    right: calc(20px * var(--mobile-scale));  /* 오른쪽 여백을 늘려 버튼을 왼쪽으로 이동 */
  }

  .submit-button {
    width: 100%;
    height: calc(50px * var(--mobile-scale));
  }

  .field-group {
    margin-bottom: calc(10px * var(--mobile-scale));
  }

  .message-container {
    height: calc(50px * var(--mobile-scale));
    padding: calc(20px * var(--mobile-scale));
  }
}

