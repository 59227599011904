:root {
  height: -webkit-fill-available;
}

html, body {
  height: 100vh;
  height: -webkit-fill-available;
}

.header-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.header-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color 0.3s;
}

.overlay-active {
  background-color: rgba(0, 0, 0, 0.9);
}

.header-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  padding: 0 calc(250px * var(--scale));
  box-sizing: border-box;
}

.header-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(20px * var(--scale)) 0;
}

.header-logo {
  display: flex;
  align-items: center;
}

.header-logo img {
  width: calc(32px * var(--scale));
  height: calc(32px * var(--scale));
}

.header-logo span {
  font-size: calc(20px * var(--scale));
  font-weight: 600;
  margin-left: calc(10px * var(--scale));
  color: white;
}

.header-nav-right {
  display: flex;
  align-items: center;
  gap: calc(10px * var(--scale));
}

.hamburger-icon {
  display: none;
  font-size: calc(24px * var(--scale));
  color: white;
  cursor: pointer;
}

.header-menu {
  display: flex;
  gap: calc(20px * var(--scale));
}

.header-menu-item {
  font-size: calc(16px * var(--scale));
  color: #9CA3AF;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
}

.header-menu-item:hover {
  color: white;
}

.header-main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.header-title {
  font-size: calc(60px * var(--scale));
  font-weight: bold;
  margin-bottom: calc(20px * var(--scale));
}

.header-subtitle {
  font-size: calc(24px * var(--scale));
  margin-bottom: calc(40px * var(--scale));
}

.header-app-store-container {
  display: flex;
  gap: calc(20px * var(--scale));
}

.header-app-store-box {
  width: calc(400px * var(--scale));
  height: calc(400px * var(--scale));
  border: calc(1px * var(--scale)) solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.header-app-store-box img:first-child {
  width: calc(112px * var(--scale));
  height: calc(124px * var(--scale));
}

.header-app-store-box img:last-child {
  width: calc(124px * var(--scale));
  height: calc(124px * var(--scale));
  margin-top: calc(10px * var(--scale));
}

.header-app-store-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 0;
  transition: opacity 0.3s;
}

.header-app-store-box:hover .header-app-store-overlay {
  opacity: 1;
}

/* Adjustments for Google Play logo */
.header-app-store-box.google-play img:first-child {
  width: calc(129px * var(--scale));
  height: calc(124px * var(--scale));
}

/* Icon size adjustments */
.header-app-store-icon {
  width: calc(107px * var(--scale));
  height: calc(124px * var(--scale));
}

.header-app-store-qr {
  width: calc(124px * var(--scale));
  height: calc(124px * var(--scale));
  margin-top: calc(10px * var(--scale));
}

.header-video-container-2 {
  position: relative;
  width: calc(1920px * var(--scale));
  height: calc(1080px * var(--scale));
}

@media screen and (max-width: 431px) {
  .header-content {
    padding: 0 calc(20px * var(--mobile-scale));
  }
  
  .header-logo {
    display: flex;
    align-items: center;
  }

  .header-logo img {
    width: calc(24px * var(--mobile-scale));
    height: calc(24px * var(--mobile-scale));
  }
  .header-logo span {
    font-size: calc(16px * var(--mobile-scale));
    font-weight: 500;
    margin-left: calc(10px * var(--mobile-scale));
    color: white;
  }
  
  .hamburger-icon {
    display: block;
    font-size: calc(24px * var(--mobile-scale));
    margin-left: calc(10px * var(--mobile-scale));
  }

  .header-nav-right {
    flex-direction: row-reverse;
  }

  .header-menu {
    display: none;
  }

  .header-menu.mobile.active {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 75%;
    background-color: rgba(0, 0, 0, 0.9);
    justify-content: flex-start;
    align-items: flex-start;
    padding: calc(20px * var(--mobile-scale));
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
    z-index: 100;
  }

 
  .menu-close {
    align-self: flex-end;
    cursor: pointer;
    margin-bottom: calc(20px * var(--mobile-scale));
  }

  .close-icon {
    width: calc(24px * var(--mobile-scale));
    height: calc(24px * var(--mobile-scale));
    position: relative;
  }

  .close-icon::before,
  .close-icon::after {
    content: '';
    position: absolute;
    width: 100%;
    height: calc(2px * var(--mobile-scale));
    background-color: white;
    top: 50%;
    left: 0;
  }

  .close-icon::before {
    transform: rotate(45deg);
  }

  .close-icon::after {
    transform: rotate(-45deg);
  }

  .mobile-menu-items {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  .header-menu-item.mobile {
    font-size: calc(18px * var(--mobile-scale));
    margin-bottom: calc(20px * var(--mobile-scale));
    color: #9CA3AF;
  }

  .header-menu-item.mobile:hover {
    color: white;
  }


  .header-main-content {
    padding-top: calc(60px * var(--mobile-scale));
  }

  .header-title {
    font-size: calc(30px * var(--mobile-scale));
  }

  .header-subtitle {
    font-size: calc(16px * var(--mobile-scale));
    margin-bottom: calc(20px * var(--mobile-scale));
  }

  .header-app-store-container {
    flex-direction: row;
    justify-content: center;
    gap: calc(10px * var(--mobile-scale));
  }
  .header-app-store-box {
    width: calc(179px * var(--mobile-scale));
    height: calc(179px * var(--mobile-scale));
    padding: calc(10px * var(--mobile-scale));
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header-app-store-icon {
    width: auto;
    height: auto;
  }

  .header-app-store-box:not(.google-play) .header-app-store-icon {
    width: calc(81px * var(--mobile-scale));
    height: calc(67px * var(--mobile-scale));
  }

  .header-app-store-box.google-play .header-app-store-icon {
    width: calc(97px * var(--mobile-scale)) !important;
    height: calc(67px * var(--mobile-scale)) !important;
  }

  .header-app-store-qr {
    display: none;  /* QR 코드 숨기기 */
  }

  .header-app-store-download {
    font-size: calc(14px * var(--mobile-scale));
    color: white;
    margin-top: calc(5px * var(--mobile-scale));
  }

  .header-video-container-2 {
    width: 100%;
    height: 100vh;
  }
}

/* Media Queries for screens 432px or above */
@media screen and (min-width: 432px) {
  .header-nav-right {
    justify-content: flex-end;
    gap: calc(10px * var(--scale));
  }
  
  .header-menu {
    display: flex;
    align-items: center;
  }
}

