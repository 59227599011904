:root {
  --mobile-scale: 1;
  --scale: 1;
}


.carousel-section {
  background-color: #fff;
  width: calc(1920px * var(--scale));
  height: calc(1080px * var(--scale));
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel-container {
  display: flex;
  align-items: center;
  transition: transform 0.5s ease;
  width: calc((900px + 762px + 60px) * var(--scale));
  margin-bottom: calc(80px * var(--scale));
}

.carousel-card {
  flex: 0 0 auto;
  background-size: cover;
  background-position: center;
  border-radius: calc(20px * var(--scale));
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
}

.carousel-card:not(.carousel-card-center) {
  width: calc(762px * var(--scale));
  height: calc(762px * var(--scale) * (684 / 900));
}

.carousel-card-center {
  width: calc(900px * var(--scale));
  height: calc(684px * var(--scale));
  margin-right: calc(30px * var(--scale));
  margin-left: calc(30px * var(--scale));
}

.carousel-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: calc(20px * var(--scale));
}

.carousel-card-title {
  color: #9CA3AF;
  margin-top: calc(50px * var(--scale));
  margin-bottom: calc(10px * var(--scale));
}

.carousel-card:not(.carousel-card-center) .carousel-card-title {
  font-size: calc(18px * var(--scale) * (762 / 900));
}

.carousel-card-center .carousel-card-title {
  font-size: calc(18px * var(--scale));
}

.carousel-card-subtitle {
  color: white;
  font-weight: 500;
  white-space: pre-line;
}

.carousel-card:not(.carousel-card-center) .carousel-card-subtitle {
  font-size: calc(24px * var(--scale) * (762 / 900));
}

.carousel-card-center .carousel-card-subtitle {
  font-size: calc(24px * var(--scale));
}

.carousel-card-images {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.carousel-card:not(.carousel-card-center) .carousel-card-images {
  bottom: calc(50px * var(--scale) * (553 / 684));
  gap: calc(20px * var(--scale) * (762 / 900));
}

.carousel-card-center .carousel-card-images {
  bottom: calc(50px * var(--scale));
  gap: calc(20px * var(--scale));
}

.carousel-card-images img {
  width: auto;
  height: auto;
}

.carousel-card:not(.carousel-card-center) .carousel-card-images img {
  width: calc(197px * var(--scale) * (762 / 900));
  height: calc(411px * var(--scale) * (762 / 900));
}

.carousel-card-center .carousel-card-images img {
  width: calc(197px * var(--scale));
  height: calc(411px * var(--scale));
}

.carousel-button-container {
  position: absolute;
  bottom: calc(100px * var(--scale));
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  gap: calc(20px * var(--scale));
  z-index: 10;
}

.carousel-button {
  width: calc(50px * var(--scale));
  height: calc(50px * var(--scale));
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(20px * var(--scale));
  font-weight: bold;
  background-color: #F3F4F6;
  color: #9CA3AF;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.carousel-button:hover {
  background-color: #E5E7EB;
  transform: scale(1.05);
  color: #000000;
}

/* Main content and sub-images styles */
.main-container {
  position: relative;
  width: 100%;
  height: calc(540px * var(--scale));
  max-width: 100vw;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
}

.sub-images-container {
  position: absolute;
  bottom: calc(-10px * var(--scale));
  left: 41%;
  transform: translateX(-50%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: calc(20px * var(--scale));
  z-index: 1;
  transition: all 1s ease;
}

.sub-images-container.hidden {
  bottom: calc(-100px * var(--scale));
}

.sub-images-container-2 {
  position: absolute;
  bottom: calc(-10px * var(--scale));
  left: 28%;
  transform: translateX(-50%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: calc(20px * var(--scale));
  z-index: 1;
  transition: all 1s ease;
}

.sub-images-container-2.hidden {
  bottom: calc(-100px * var(--scale));
}

.text-content-2 {
  position: absolute;
  top: 20%;
  left: 13%;
  transform: translateY(-50%);
  opacity: 1;
  font-size: calc(36px * var(--scale));
  font-weight: 600;
  line-height: 1.2;
  transition: all 1s ease;
}

.text-content-2.hidden {
  top: 40%;
  opacity: 0;
}

.sub-image {
  height: auto;
  max-height: calc(400px * var(--scale));
  width: auto;
}

/* Additional styles for carousel card images */
.carousel-card-images img {
  width: calc(197px * var(--scale));
  height: calc(411px * var(--scale));
}

/* Adjustments for center card */
.carousel-card-center .carousel-card-images img {
  width: calc(197px * var(--scale));
  height: calc(411px * var(--scale));
}


@media (max-width: 431px) {
  .carousel-section {
    height: calc(1870px * var(--mobile-scale)); /* 935px로 높이 지정 */
    width: 100%;
    z-index: 10;
    padding: calc(20px * var(--mobile-scale)) 0;
    
  }

  .carousel-container {
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
  }

  .carousel-card {
    width: calc(390px * var(--mobile-scale))!important;
    height: calc(376px * var(--mobile-scale))!important;
    margin: calc(10px * var(--mobile-scale)) 0;
  }

  .carousel-card-center {
    width: calc(390px * var(--mobile-scale))!important;
    height: calc(376px * var(--mobile-scale))!important;
    margin: calc(10px * var(--mobile-scale)) 0;
  }

  .carousel-card-title {
    font-size: calc(16px * var(--mobile-scale))!important;
    
  }

  .carousel-card-subtitle {
    margin-bottom: calc(10px * var(--mobile-scale));
    font-size: calc(18px * var(--mobile-scale))!important;
  }

  .carousel-card-images {
    bottom: calc(20px * var(--mobile-scale))!important;
  }

  .carousel-card-images img {
    width: calc(100px * var(--mobile-scale))!important;
    height: calc(210px * var(--mobile-scale))!important;
  }

  .carousel-button-container {
    display: none;
  }

  .safe-app-title-container {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-right: calc(20px * var(--mobile-scale));
    margin-bottom: calc(10px * var(--mobile-scale));
  }

  .safe-app-title {
    font-size: calc(30px * var(--mobile-scale));
    margin-left: calc(10px * var(--mobile-scale));
    font-weight: 500;
  }

  .safe-app-title-line {
    width: calc(3px * var(--mobile-scale));
    height: calc(30px * var(--mobile-scale));
    background-color: #D1D5DB;
  }
}