:root {
  --mobile-scale: 1;
  --scale: 1;
}

.video-section {
  background-color: #fff;
  color: #141414;
  width: calc(1920px * var(--scale));
  height: calc(1080px * var(--scale));
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-title {
  font-size: calc(48px * var(--scale));
  font-weight: 500;
  color: #141414;
  margin-bottom: calc(40px * var(--scale));
  position: absolute;
  top: calc((1080px * var(--scale) - 680px * var(--scale)) / 2 - 98px * var(--scale));
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.video-container {
  position: relative;
  width: calc(1240px * var(--scale));
  height: calc(680px * var(--scale));
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-pause-button {
  position: absolute;
  bottom: calc(20px * var(--scale));
  right: calc(20px * var(--scale));
  width: calc(60px * var(--scale));
  height: calc(60px * var(--scale));
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
}

.play-pause-icon {
  width: calc(60px * var(--scale));
  height: calc(60px * var(--scale));
}

@media screen and (max-width: 431px) {
  .video-section { 
    height: 100vh; 
    padding: calc(20px * var(--mobile-scale));
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .video-title { 
    font-size: calc(30px * var(--mobile-scale));
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* 절대 위치에서 상대 위치로 변경 */
    top: auto; /* 자동으로 설정 */
    left: auto; /* 자동으로 설정 */
    transform: none; /* 변환 제거 */
    width: 100%;
    margin-bottom: calc(60px * var(--mobile-scale)); /* 비디오 컨테이너와의 간격 추가 */
  }

  .video-title::before {
    content: "";
    width: calc(3px * var(--mobile-scale));
    height: calc(30px * var(--mobile-scale));
    background-color: #D1D5DB;
    margin-right: calc(10px * var(--mobile-scale));
  }

  .video-container {
    width: calc(390px * var(--mobile-scale));
    height: calc(378px * var(--mobile-scale));
    margin-top: calc(-40px * var(--mobile-scale));
    
  }

  .play-pause-button {
    bottom: calc(10px * var(--mobile-scale)); 
    right: calc(10px * var(--mobile-scale)); 
    width: calc(60px * var(--mobile-scale));
    height: calc(60px * var(--mobile-scale));
  }

  .play-pause-icon {
    width: calc(60px * var(--mobile-scale)); 
    height: calc(60px * var(--mobile-scale));
  }
}