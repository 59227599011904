@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Pretendard";
    src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Pretendard";
    src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
  }

  html, body {
    @apply m-0 p-0 w-full h-full overflow-hidden font-sans antialiased;
    text-underline-position: under;
    line-height: 1.5;
    text-rendering: optimizeLegibility;
  }

  #root {
    @apply w-full h-full flex flex-col;
  }
}

@layer components {
  .phasic-container {
    @apply flex flex-col min-h-[92vh] w-screen p-[6vh_5vw_6vh_20vw] mt-[8vh] shadow relative overflow-y-auto bg-white;
  }

  .phasic-container::-webkit-scrollbar {
    @apply w-[0.42vw] h-[0.42vw];
  }

  .phasic-container::-webkit-scrollbar-thumb {
    @apply bg-gray-400 rounded;
  }

  .phasic-container::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-600;
  }
}