:root {
  --scale: 1;
  --mobile-scale: 1;
}

.footer-section {
  background-color: #272727;
  color: #F9FAFB;
  width: 100%;
  height: auto;
  padding: calc(80px * var(--scale));
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-logo {
  width: calc(100px * var(--scale));
  height: auto;
  margin-bottom: calc(20px * var(--scale));
}

.footer-text {
  font-size: calc(22px * var(--scale));
  line-height: 1.5;
  color: #F9FAFB;
  margin-bottom: calc(10px * var(--scale));
}

.footer-line {
  font-size: calc(22px * var(--scale));
  line-height: 1.5;
  color: #F9FAFB;
  margin: 0 calc(25px * var(--scale));
}

.footer-row {
  display: flex;
  align-items: center;
}

.footer-link {
  font-size: calc(22px * var(--scale));
  line-height: 1.5;
  color: #F9FAFB;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: color 0.3s;
}

.footer-link-underline {
  border-bottom: 1px solid #F9FAFB;
}

.footer-link:hover,
.footer-link-underline:hover {
  color: #9CA3AF;
  border-bottom-color: #9CA3AF;
}

.footer-copyright {
  font-size: calc(22px * var(--scale));
  line-height: 1.5;
  color: #F9FAFB;
}

.arrow-icon {
  margin-left: calc(5px * var(--scale));
  font-size: calc(22px * var(--scale));
  transition: color 0.3s;
}

.inquiry-link {
  margin-left: auto;
  margin-right: 6vw;
}

@media screen and (max-width: 431px) {
  .footer-section {
    padding: calc(60px * var(--mobile-scale)) calc(20px * var(--mobile-scale));
  }

  .footer-logo {
    width: calc(80px * var(--mobile-scale));
    margin-bottom: calc(15px * var(--mobile-scale));
  }

  .footer-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-text, .footer-link, .footer-copyright {
    font-size: calc(17px * var(--mobile-scale));
    margin-bottom: calc(10px * var(--mobile-scale));
  }

  .footer-line {
    display: none;
  }

  .footer-row:last-child {
    position: relative;
    padding-bottom: calc(60px * var(--mobile-scale));
  }

  .footer-copyright {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: calc(10px * var(--mobile-scale));
    border-top: 1px solid #F9FAFB;
  }

  .inquiry-link {
    margin-left: 0;
    margin-right: 0;
    margin-top: calc(50px * var(--mobile-scale));
  }

  .arrow-icon {
    font-size: calc(14px * var(--mobile-scale));
  }
}