:root {
  --scale: 1;
  --mobile-scale: 1;
}

.headerSection {
  background-color: #F3F4F6;
  color: #141414;
  width: 100%;
  width: calc(1920px * var(--scale));
  height: calc(200px * var(--scale));
  position: relative;
  overflow: hidden;
}

.headerContent {
  position: absolute;
  bottom: 10%;
  left: 13%;
  transform: translateY(-50%);
  font-size: calc(48px * var(--scale));
  font-weight: 500;
  line-height: 1.2;
  display: flex;
  align-items: center;
}

.verticalLine {
  width: calc(6px * var(--scale));
  height: calc(36px * var(--scale));
  background-color: #D1D5DB;
  margin-right: calc(20px * var(--scale));
}

.main1Container {
  position: relative;
  width: 100%;
  height: calc(540px * var(--scale));
  max-width: 100vw;
}

.main1Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
}

.overlay50 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.subImagesContainer,
.subImagesContainer2 {
  position: absolute;
  bottom: calc(-100px * var(--scale));
  left: 41%;
  transform: translateX(-50%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: calc(20px * var(--scale));
  z-index: 2;
  transition: all 1s ease;
}

.subImagesContainer.visible,
.subImagesContainer2.visible  {
  bottom: calc(-10px * var(--scale));
}

.subImage {
  height: auto;
  width: calc(537px * var(--scale));
}
.subImage2 {
  height: auto;
  width: calc(270px * var(--scale));
}

.textSection {
  background-color: #F3F4F6;
  color: #141414;
  width: 100%;
  width: calc(1920px * var(--scale));
  height: calc(340px * var(--scale));
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.textContent,
.textContent2{
  position: absolute;
  top: 40%;
  left: 13%;
  transform: translateY(-50%);
  opacity: 0;
  font-size: calc(36px * var(--scale));
  font-weight: 500;
  line-height: 1.2;
  transition: all 1s ease;
}

.textContent.visible {
  top: 20%;
  opacity: 1;
}

.multilineText {
  white-space: pre-line;
}
.overlay10 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.subImagesContainer2 {
  left: 28%;
}

.textContent2 {
  top: 40%;
  opacity: 0;
  transition: all 1s ease;
}

.textContent2.visible {
  top: 20%;
  opacity: 1;
}


@media (max-width: 431px) {
  .headerSection {
    display: flex;
    align-items: center;
    align-self: flex-start;
    height: calc(186px * var(--mobile-scale));
    width: 100%;
  }
  
  .headerContent {
    font-size: calc(30px * var(--mobile-scale));
    bottom: 10%;
    left: 7%;
    position: relative;
    transform: none;
  }
  
  .verticalLine {
    width: calc(3px * var(--mobile-scale));
    height: calc(30px * var(--mobile-scale));
    margin-right: calc(10px * var(--mobile-scale));
  }
  
  .main1Container {
    width: 100%;
    height: calc(495px * var(--mobile-scale));
    position: relative;
  }

  .main1Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .mobileSubImagesContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(408px * var(--mobile-scale));
    height: calc(240px * var(--mobile-scale));
    z-index: 3;
  }
  
  .mobileSubImage {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  .navButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    z-index: 4;
  }
  
  
  .prevButton {
    left: -20px;
  }
  
  .nextButton {
    right: -20px;
  }

  .navButton.light svg {
    color: rgba(0, 0, 0, 0.6);
  }

  .navButton.dark svg {
    color: rgba(0, 0, 0, 0.2);
  }


  .textSection {
    height: calc(230px * var(--mobile-scale));
    padding: calc(20px * var(--mobile-scale));
    width: 100%;
  }
  
  .textContent,
  .textContent2 {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    font-size: calc(24px * var(--mobile-scale));
    opacity: 1;
  }
  
  .textContent.visible,
  .textContent2.visible {
    top: 0;
  }
  
  .subImage {
    width: calc(282px * var(--mobile-scale)) !important;
    height: calc(166px * var(--mobile-scale)) !important;
    object-fit: cover;
  }
  .subImagesContainer.visible,
  .subImagesContainer2.visible {
    top: 50%;  /* 최종 위치로 이동 */
    opacity: 1;  /* 완전히 보이게 설정 */
  }

  
  .subImagesContainer2 {
    position: absolute;
    top: calc(50% + 10px);  /* 초기 위치를 10px 아래로 설정 */
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-content: center;
    gap: calc(20px * var(--mobile-scale));
    width: 100%;
    opacity: 0;  /* 초기에는 투명하게 설정 */
    transition: top 0.5s ease, opacity 0.5s ease;  /* 상단 위치와 투명도에 대한 전환 효과 추가 */
  }
  
  
  .subImage2 {
    width: calc(188px * var(--mobile-scale)) !important;
    height: calc(266px * var(--mobile-scale)) !important;
    object-fit: cover;
  }

  .textSection {
    height: calc(230px * var(--mobile-scale));
    padding: calc(20px * var(--mobile-scale));
    width: 100%;
  }
  
  .textContent,
  .textContent2 {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    font-size: calc(24px * var(--mobile-scale));
    opacity: 1;
  }
  
  .textContent.visible,
  .textContent2.visible {
    top: 0;
  }
} 