:root {
  --mobile-scale: 1;
  --scale: 1;
}

.lang-dropdown {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: calc(8px * var(--scale));
  transition: color 0.3s;
}

.lang-text {
  font-size: calc(16px * var(--scale));
  transition: color 0.3s;
}

.drop-icon {
  width: calc(18px * var(--scale));
  height: calc(10px * var(--scale));
  transition: transform 0.3s ease, filter 0.3s;
}

.lang-icon {
  width: calc(16px * var(--scale));
  height: calc(16px * var(--scale));
}

.lang-options {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 10;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.2s ease-in-out;
  border: calc(0.1px * var(--scale)) solid white;
}

.lang-options.open {
  transform: scaleY(1);
}

.lang-option {
  padding: calc(10px * var(--scale)) calc(20px * var(--scale));
  color: #9CA3AF;
  font-size: calc(14px * var(--scale));
  cursor: pointer;
  white-space: nowrap;
}

.lang-option:hover {
  color: white;
}

@media screen and (max-width: 431px) {
  .lang-dropdown {
    gap: calc(6px * var(--mobile-scale));
  }

  .lang-text {
    font-size: calc(16px * var(--mobile-scale));
  }

  .drop-icon {
    width: calc(16px * var(--mobile-scale));
    height: calc(12px * var(--mobile-scale));
  }

  .lang-icon {
    display: none;  /* 431px 이하에서 언어 아이콘 숨김 */
  }

  .lang-options {
    border: calc(0.1px * var(--mobile-scale)) solid white;
  }

  .lang-option {
    padding: calc(15px * var(--mobile-scale)) calc(30px * var(--mobile-scale));
    font-size: calc(16px * var(--mobile-scale));
  }
}