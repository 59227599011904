/* SafeWeb CSS 스타일 (스케일 적용) */

.scroll-top-button {
  position: fixed;
  bottom: calc(30px * var(--scale));
  right: calc(30px * var(--scale));
  width: calc(80px * var(--scale));
  height: calc(80px * var(--scale));
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 0.3s;
  opacity: 0;
}

.scroll-top-button.visible {
  opacity: 1;
}

.scroll-top-icon {
  width: 100%;
  height: 100%;
}

.multiline-text {
  white-space: pre-line;
}

.scroll-top-button-hidden {
  display: none;
}

@media (max-width: 430px) {
  :root {
    --scale: var(--mobile-scale);
  }
  .scroll-top-button {
    bottom: calc(20px * var(--mobile-scale));
    right: calc(20px * var(--mobile-scale));
    width: calc(50px * var(--mobile-scale));
    height: calc(50px * var(--mobile-scale));
  }
}